<template>
  <table-style-wrapper>
    <table-wrapper class="table-responsive">
      <a-table
        size="small"
        :pagination="false"
        :columns="columns"
        :data-source="data"
        :rowKey="(row) => row.key"
      >
        <template #value="{record}">
          <template v-if="record.key === 'route'">
            <text-field
              class="editable-table-cell hide-input-label"
              size="small"
              fieldKey="route"
              :rules="requiredFields ? [required] : []"
              :field-value="data[1].value"
              @update:fieldValue="handleFieldChange"
            />
          </template>
          <template v-else>
            {{record.value}}
          </template>
        </template>
      </a-table>
    </table-wrapper>
  </table-style-wrapper>
</template>

<script>
import {
  computed, defineComponent, ref, watch
} from 'vue';
import VueTypes from 'vue-types';
import TextField from '@/components/shared/fields/Text';
import {TableStyleWrapper} from '@/components/tables/style.js';
import {TableWrapper} from '@/components/shared/styledComponents/Main';
import {required} from '@/helpers/validationRules';
import _ from 'lodash';
import {useI18n} from 'vue-i18n';

export default defineComponent({
  name: 'ShipmentQuoteShipmentDetails',
  props: {
    shipment: VueTypes.object,
    route: VueTypes.string,
    requiredFields: VueTypes.bool
  },
  components: {
    TextField,
    TableStyleWrapper,
    TableWrapper
  },
  setup(props, context) {
    const {t} = useI18n();
    const columns = computed(() => [
      {
        title: t('views.shipmentQuote.shipmentDetails'),
        dataIndex: 'label'
      },
      {
        dataIndex: 'value',
        slots: {customRender: 'value'}
      }
    ]);

    const data = ref([]);

    const handleFieldChange = (value) => {
      data.value[1].value = value;
      context.emit('updateSubForm', value);
    };

    watch(() => props.shipment, (shipment) => {
      if (_.isEmpty(shipment)) return;

      data.value = [];
      _.forEach(['destinationCountryName', 'route', 'totalValue', 'currency', 'totalWeight', 'weightUnit', 'cifValue'], (field) => {
        if (typeof(shipment[field]) === 'number') shipment[field] = _.round(shipment[field], 2);
        data.value.push({
          key: field, label: t(`models.shipment.attributes.${field}`), value: field === 'route' ? '' : shipment[field]
        });
      });

      handleFieldChange(props.route);
    }, {deep: true, immediate: true});

    return {
      columns,
      data,
      required,
      handleFieldChange
    };
  }
});
</script>

<style lang="scss" scoped>
.editable-table-cell.hide-input-label {
  padding-top: 10px;
}
</style>
