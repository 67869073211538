<template>
  <app-header :routes="routes" />
  <Main>
    <quote-form-wrapper>
      <sdCards headless>
        <FormValidationWrap>
          <VerticalFormStyleWrap>
            <a-form
              v-if="!$_.isEmpty(shipmentQuote)"
              ref="formRef"
              name="sDash_validation-form"
              layout="vertical"
              :model="formData"
              :validateOnRuleChange="false"
            >
              <a-row :gutter="10">
                <a-col :xs="22" :sm="20" :md="20" :lg="14">
                  <agent-info
                    :reference="shipmentQuote?.reference"
                    :requiredFields="requiredFields"
                    :companyName="agent"
                    @updateSubForm="formData['reference'] = $event"
                  />
                </a-col>
                <a-col :xs="22" :sm="20" :md="20" :lg="14">
                  <shipment-details
                    :route="shipmentQuote?.route"
                    :shipment="shipment"
                    :requiredFields="requiredFields"
                    @updateSubForm="formData['route'] = $event"
                  />
                </a-col>
                <a-col
                  v-for="(isVisible, category) in quoteCategoriesVisible"
                  :xs="24"
                  :key="category"
                >
                  <a-row v-if="isVisible">
                    <a-col
                      class="quote-table-forms"
                      :xs="20" :sm="22"
                    >
                      <category-items
                        :parentKey="category"
                        :categoryId="categoryId(category)"
                        :title="$t(`models.priceCategory.attributes.categoryTypes.${category}`)"
                        :items="linkedSubcategoryItems(category)"
                        :unlinkedItems="unlinkedSubCategoryItems(category)"
                        :destroyAll="!quoteCategoriesVisible[category]"
                        @updateSubForm="formData[category] = $event"
                      />
                    </a-col>
                    <a-col :xs="4" :md="2">
                      <sdButton
                        v-if="canRemove(category)"
                        outlined
                        class="btn-icon remove-item-btn"
                        type="default"
                        shape="circle"
                        :tooltip="$t('actions.remove')"
                        @click="removeCategory($event, category)"
                      >
                        <sdFeatherIcons type="minus-circle" stroke="#E83557" size="14" html-type="button" />
                      </sdButton>
                    </a-col>
                  </a-row>
                </a-col>
                <a-col :xs="24" class="quote-total-summary">
                  <a-row>
                    <a-col :xs="12" :md="6" class="total-text">
                      {{$t('models.shipmentQuote.attributes.grandTotal')}}
                    </a-col>
                    <a-col :xs="12" :md="12" class="total-value">{{grandTotal}}</a-col>
                  </a-row>
                </a-col>
                <a-col :xs="22" class="pl-20 pr-20">
                  <a-row>
                    <a-col :xs="3">
                      <h3 class="table-final-notes-label">{{$t('models.shipmentQuote.attributes.notes')}}</h3>
                    </a-col>
                    <a-col :xs="21">
                      <text-area
                        v-model:field-value="formData['note']"
                        fieldKey="note"
                      />
                    </a-col>
                  </a-row>
                </a-col>
              </a-row>
              <div class="sDash_form-action">
                <dropdown
                  v-if="categoryOptions.length > 0"
                  :title="$t('views.shipmentQuote.addCategory')"
                  :isButton="true"
                  :loading="loading"
                  :disabled="loading"
                  :options="categoryOptions"
                  @selectedOption="addCategory"
                />
                <a-button
                  v-if="!isAdditionalQuote && !isEditMode &&! fromTemplate"
                  class="btn-signin mr-10 float-button-right"
                  size="small"
                  type="primary"
                  :loading="loading"
                  :disabled="loading"
                  @click="submitForm(true)"
                >
                  <span>{{$t('actions.saveDraft')}}</span>
                </a-button>
                <a-button
                  class="btn-signin mr-10 float-button-right"
                  size="small"
                  type="primary"
                  :loading="loading"
                  :disabled="loading"
                  @click="submitForm()"
                >
                  <span>{{$t('actions.submit')}}</span>
                </a-button>
              </div>
            </a-form>
          </VerticalFormStyleWrap>
        </FormValidationWrap>
      </sdCards>
    </quote-form-wrapper>
  </Main>
</template>

<script>
import {
  computed, defineComponent, reactive, ref, watch, nextTick
} from 'vue';
import {useStore} from 'vuex';
import {useRouter} from 'vue-router';
import _ from 'lodash';
import {Main} from '@/components/shared/styledComponents/Main';
import AgentInfo from '@/components/shipmentQuoteForm/AgentInfo';
import ShipmentDetails from '@/components/shipmentQuoteForm/ShipmentDetails';
import CategoryItems from '@/components/shipmentQuoteForm/CategoryItems';
import Dropdown from '@/components/dropdown/Dropdown';
import TextArea from '@/components/shared/fields/TextArea';
import {QuoteFormWrapper} from './style';
import {VerticalFormStyleWrap, FormValidationWrap} from '@/components/shared/form/Style';
import {scrollToFirstError} from '@/helpers/scrollToFirstErrorInForm';

export default defineComponent({
  name: 'ShipmentQuoteForm',
  components: {
    Main,
    AgentInfo,
    ShipmentDetails,
    CategoryItems,
    Dropdown,
    VerticalFormStyleWrap,
    FormValidationWrap,
    TextArea,
    QuoteFormWrapper
  },
  setup() {
    const {
      dispatch, getters, state
    } = useStore();
    const router = useRouter();

    const formData = reactive({});
    const quoteCategoriesVisible = reactive({});

    const isAdditionalQuote = computed(() => router.currentRoute.value.name === 'AgentAdditionalQuoteForm');
    const isEditMode = computed(() => router.currentRoute.value.name === 'AgentEditQuoteForm');
    const fromTemplate = computed(() => router.currentRoute.value.query.template === 'true');
    const shipmentId = computed(() => router.currentRoute.value.params.shipmentId);
    const shipmentRequestId = computed(() => router.currentRoute.value.params.shipmentRequestId);
    const shipmentQuote = computed(() => getters.shipmentQuote);
    const shipment = computed(() => shipmentQuote.value._jv?.relationships.shipment);
    const agent = computed(() => state.session.currentUser._jv?.relationships.company.name);

    const categoryOptions = computed(() => {
      const options = [];
      _.forEach(quoteCategoriesVisible, (visible, key) => {
        if (!visible) options.push({value: key, label: key});
      });
      return options;
    });

    const init = async () => {
      dispatch('toggleLoading', true);
      if (isEditMode.value) {
        await dispatch('getShipmentQuote', {
          id: router.currentRoute.value.params.id,
          params: {[_.snakeCase('detailedQuote')]: true}
        });
      } else if (fromTemplate.value) {
        await dispatch('getQuoteTemplate', {
          params: {
            [_.snakeCase('skipIds')]: true,
            [_.snakeCase('shipmentId')]: shipmentId.value,
            [_.snakeCase('detailedQuote')]: true
          }
        });
      } else {
        await dispatch('getQuoteByShipment', {
          shipmentId: shipmentId.value,
          shipmentRequestId: shipmentRequestId.value,
          params: {
            [_.snakeCase('additionalQuote')]: isAdditionalQuote.value || undefined,
            [_.snakeCase('detailedQuote')]: true,
            fields: isAdditionalQuote.value ? ['unlinkedSubCategories'] : ['categories', 'reference', 'route']
          }
        });
      }

      Object.assign(quoteCategoriesVisible, {});
      Object.assign(formData, {note: shipmentQuote.value.note || ''});
      const orderedQuoteCategories = _.sortBy(shipmentQuote.value.categories, ({name}) => name !== 'service');
      _.forEach(orderedQuoteCategories, (category) => quoteCategoriesVisible[category.name] = true);
      _.forEach(shipmentQuote.value.unlinkedSubCategories, (category) => {
        if (!getCategory(category.name, 'categories')) {
          quoteCategoriesVisible[category.name] = false;
        }
      });
      dispatch('toggleLoading', false);
    };

    const addCategory = (key) => quoteCategoriesVisible[key] = true;
    const canRemove = (category) => !_.includes(['duties', 'service', 'vat'], category);
    const removeCategory = (e, key) => {
      e.preventDefault();
      if (!_.isEmpty(getCategory(key, 'unlinkedSubCategories'))) {
        _.unset(formData, key);
      } else {
        _.forEach(formData[key], (item) => item._destroy = 1);
      }
      quoteCategoriesVisible[key] = false;
    };

    watch(formData, (data) => {
      _.forEach(data, (category, key) => {
        if (key === 'note' || key === 'reference' || key === 'route') return;

        if (_.every(category, (item) => item._destroy === 1)) quoteCategoriesVisible[key] = false;
      });
    }, {deep: true, nested: true});

    const grandTotal = computed(() => {
      const formDataFields = _.filter(formData, (value, key) => key !== 'route' && key !== 'reference');
      const subCategories = _.filter(_.compact(_.flatten(_.values(formDataFields))), (item) => item['_destroy'] !== 1);
      if (_.isEmpty(subCategories)) return 0;
      return _.round(_.sumBy(subCategories, (item) => _.round(item.value, 2)), 2);
    });

    const formRef = ref();
    const loading = ref(false);
    const requiredFields = ref(true);
    const submitForm = async (saveDraft = false) => {
      requiredFields.value = !saveDraft;
      await nextTick();
      formRef.value
        .validate()
        .then(async () => {
          loading.value = true;
          const form = {[_.snakeCase('quoteSubCategoriesAttributes')]: [], [_.snakeCase('shipmentId')]: shipmentId.value};
          _.forEach(formData, (value, key) => {
            if (key === 'route' || key === 'reference' || key === 'note') form[key] = value;
            else form[_.snakeCase('quoteSubCategoriesAttributes')].push(...value);
          });
          form['from_template'] = fromTemplate.value;
          const action = isAdditionalQuote.value || fromTemplate.value ? 'createShipmentQuote' : 'updateShipmentQuote';
          const {status} = await dispatch(action, {
            id: shipmentQuote.value._jv.id, form, saveDraft, updateType: 'submit'
          });
          if (status === 200) router.push({name: 'AgentShipmentRequestDetails', params: {shipmentRequestId: shipmentRequestId.value}});
          loading.value = false;
        }).catch(({errorFields}) => {
          scrollToFirstError(formRef, errorFields);
        });
    };

    const getCategory = (categoryName, key) => _.find(shipmentQuote.value[key], ({name}) => name === categoryName);
    const linkedSubcategoryItems = (categoryName) => getCategory(categoryName, 'categories')?.subCategories;
    const unlinkedSubCategoryItems = (categoryName) => getCategory(categoryName, 'unlinkedSubCategories')?.subCategories;
    const categoryId = (categoryName) => getCategory(categoryName, 'categories')?.id || getCategory(categoryName, 'unlinkedSubCategories')?.id;

    init();

    const currentBreadCrumbName = computed(() => {
      if (isAdditionalQuote.value) return 'views.shipmentQuote.additionalQuote';
      else if (shipmentQuote.value.status === 'waiting_agent_response') return 'views.shipmentQuote.submitQuote';
      else return 'views.shipmentQuote.editQuote';
    });
    const routes = computed(() => [
      {
        name: 'AgentShipmentRequestDetails',
        breadcrumbName: 'views.shipmentRequest.title',
        params: {shipmentRequestId: shipmentRequestId.value}
      },
      {
        name: 'AgentShipmentDetails',
        breadcrumbName: 'views.shipmentRequest.shipment',
        params: {shipmentRequestId: shipmentRequestId.value, shipmentId: shipmentId.value}
      },
      {
        name: isAdditionalQuote.value ? 'AgentAdditionalQuoteForm' : 'AgentQuoteForm',
        breadcrumbName: currentBreadCrumbName.value
      }
    ]);

    return {
      loading,
      formRef,
      agent,
      formData,
      routes,
      shipmentQuote,
      shipment,
      quoteCategoriesVisible,
      categoryOptions,
      grandTotal,
      isAdditionalQuote,
      isEditMode,
      canRemove,
      removeCategory,
      addCategory,
      submitForm,
      linkedSubcategoryItems,
      unlinkedSubCategoryItems,
      categoryId,
      fromTemplate,
      requiredFields
    };
  }
});
</script>

<style lang="scss" scoped>
.add-more-button-col {
  align-self: end;
  text-align: center;
}

.quote-total-summary .ant-row {
  padding-top: 10px;
  padding-inline: 30px;
  padding-bottom: 20px;
}

.quote-total-summary .ant-col {
  align-self: center
}

.quote-total-summary .total-text,
.quote-total-summary .total-value {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.quote-table-forms > div:first-of-type {
  padding-bottom: 0px
}

.table-final-notes-label {
  line-height: 3;
  padding-left: 20px;
}
</style>
