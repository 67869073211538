<template>
  <table-style-wrapper>
    <table-wrapper class="table-responsive">
      <a-table
        size="small"
        :pagination="false"
        :columns="columns"
        :data-source="data"
        :rowKey="(row) => row.key"
      >
        <template #value="{record}">
          <template v-if="record.key === 'reference'">
            <text-field
              class="editable-table-cell hide-input-label"
              size="small"
              fieldKey="reference"
              :rules="requiredFields ? [required] : []"
              :field-value="data[2].value"
              @update:fieldValue="handleFieldChange"
            />
          </template>
          <template v-else>
            {{record.value}}
          </template>
        </template>
      </a-table>
    </table-wrapper>
  </table-style-wrapper>
</template>

<script>
import {
  computed, defineComponent, ref, watch
} from 'vue';
import VueTypes from 'vue-types';
import moment from 'moment';
import TextField from '@/components/shared/fields/Text';
import {TableStyleWrapper} from '@/components/tables/style.js';
import {TableWrapper} from '@/components/shared/styledComponents/Main';
import {required} from '@/helpers/validationRules';
import {useI18n} from 'vue-i18n';

export default defineComponent({
  name: 'ShipmentQuoteAgentInfo',
  emits: ['updateSubForm'],
  props: {
    companyName: VueTypes.string,
    reference: VueTypes.string,
    requiredFields: VueTypes.bool
  },
  components: {
    TextField,
    TableStyleWrapper,
    TableWrapper
  },
  setup(props, context) {
    const {t} = useI18n();
    const columns = computed(() => [
      {
        title: t('views.shipmentQuote.agentInfo'),
        dataIndex: 'label'
      },
      {
        dataIndex: 'value',
        slots: {customRender: 'value'}
      }
    ]);

    const data = ref([
      {
        key: 'agent', value: computed(() => props.companyName), label: t('views.shipmentQuote.agent')
      },
      {
        key: 'date', value: moment().format('DD MMM YYYY'), label: t('views.shipmentQuote.date')
      },
      {
        key: 'reference', value: '', label: t('models.shipmentQuote.attributes.reference')
      }
    ]);

    const handleFieldChange = (value) => {
      data.value[2].value = value;
      context.emit('updateSubForm', value);
    };

    watch(() => props.reference, (reference) => {
      handleFieldChange(reference || '');
    }, {immediate: true});

    return {
      columns,
      data,
      required,
      handleFieldChange
    };
  }
});
</script>

<style lang="scss" scoped>
.editable-table-cell.hide-input-label {
  padding-top: 10px;
}
</style>
